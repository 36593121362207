import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import YoutubeIcon from '../components/icons/YoutubeIcon';

import Breadcrumbs from '../components/Breadcrumbs';
import CustomMDXProvider from '../components/CustomMDXProvider';
import Link from '../components/Link';
import NOPSidebarBanner from '../components/NOPSidebarBanner';
import Seo from '../components/Seo';
import ShareButtons from '../components/ShareButtons';

import escapeForHTML from '../utils/escapeForHTML';

const SongPage = ({ data, location, pageContext }) => {
  const { song, site } = data;

  return (
    <>
      <Seo
        title={`${escapeForHTML(song.title)} - Українська народна пісня`}
        description={`Текст української пісні ${escapeForHTML(song.title)}`}
      >
        <script type='application/ld+json'>
          {`{
              "@context": "https://schema.org",
              "@type": "CreativeWork",
              "name": "${song.title}",
              "description": "Українська народна пісня: ${escapeForHTML(song.title)}",
              "abstract": "Українська народна пісня: ${escapeForHTML(song.title)}",
              "author": "${song.author}",
              "url": "${site.siteMetadata.siteUrl}${location.pathname}",
              "accessMode": "textual"
          }`}
        </script>
      </Seo>
      <div className="container-md py-10 md:py-20">
        <Breadcrumbs
          crumbs={pageContext.breadcrumb.crumbs}
          crumbLabel={song.title}
        />
        <h1 className="typo-h1 mt-4">
          {song.title}
        </h1>
        <div className="row mt-4 md:mt-10">
          <div className="col-full md:col-8">
            <CustomMDXProvider>
              <MDXRenderer>
                {song.content.body}
              </MDXRenderer>
            </CustomMDXProvider>
          </div>
          <div className="col-full md:col-4 mt-8 md:mt-0">
            <NOPSidebarBanner medium="song-sidebar" />
          </div>
        </div>
        <div className="mt-5 md:mt-14">
          <h3 className="typo-h2">Поділитися</h3>
          <ShareButtons
            className="mt-4"
            title={song.title}
            url={`${site.siteMetadata.siteUrl}${location.pathname}`}
          />
        </div>
        {song.similar && (
          <div className="mt-5 md:mt-14">
            <h2 className="typo-h2">
              Схожі пісні
            </h2>
            <ul className="row">
              {song.similar.map((song) => (
                <li className="col-full md:col-4 mt-4">
                  <Link to={song.slug} className="typo-body hover:text-red-500">
                    <span className="mr-2">
                      {song.title}
                    </span>
                    {song.hasYoutube && (
                      <YoutubeIcon className="inline w-4 h-4" />
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  )
};

export default SongPage;

export const pageQuery = graphql`
  query($slug: String!) {
    song: song(slug: { eq: $slug }) {
      title
      author
      similar {
        title
        slug
        hasYoutube
      }
      content {
        body
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
